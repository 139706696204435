import React from 'react';
import Layout from '../layouts/page-layout';
import SEO from '../utils/seo';
import Booking from '../utils/booking';

const AppoinmentsPage = () => {
  return (
    <Layout defaultColor={({ theme }) => theme.colors.lightBg} fixed={false}>
      <SEO
        title="Appointments"
        description="One of our professional exterminating experts will come to your home or office in the greater St. Louis area, and solve all your bed bug problems at a time that is convenient for you!  Simply choose an available date and time from the calendar below and request a Free Inspection."
      />
      <Booking />
    </Layout>
  );
};
export default AppoinmentsPage;
