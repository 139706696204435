import React from 'react';

const Booking = () => {
  const settings = {
    src: 'https://unitechpestcontrol.youcanbook.me',
    id: 'ycbmiframeunitechpestcontrol',
  };

  return typeof window !== 'undefined' ? (
    <>
      <iframe
        title="Appointments booking"
        src={`${settings.src}/?noframe=true&skipHeaderFooter=true`}
        id={settings.id}
        style={{
          width: '100%',
          height: '1000px',
          border: 0,
          padding: 0,
          backgroundColor: 'transparent',
        }}
        frameBorder="0"
        allowtransparency="true"
      ></iframe>

      {window.addEventListener &&
        window.addEventListener(
          'message',
          function (event) {
            if (event.origin === settings.src) {
              document.getElementById(settings.id).style.height =
                event.data + 'px';
            }
          },
          false
        )}
    </>
  ) : null;
};

export default Booking;
